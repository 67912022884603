import React, { useRef, useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';

import menus from "../../pages/menu";
import DarkMode from './DarkMode';
import logodark from '../../assets/images/logo/logo_dark.png'
import logo from '../../assets/images/logo/logo_dark.png'
import avt from '../../assets/images/avatar/avt-2.jpg'
import coin from '../../assets/images/logo/coin.svg'
import NEAR from '../../constants/near';


const HeaderStyle2WL = () => {
    
    const nearWallet = useSelector(state => state.near.wallet);    
    const [showMenu, setShowMenu] = useState(false);

    const { pathname } = useLocation();

    const headerRef = useRef(null) 
    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });
    const isSticky = (e) => {
        const header = document.querySelector('.js-header');
        const scrollTop = window.scrollY;
        scrollTop >= 300 ? header.classList.add('is-fixed') : header.classList.remove('is-fixed');
        scrollTop >= 400 ? header.classList.add('is-small') : header.classList.remove('is-small');
    };

    const menuLeft = useRef(null)
    const btnToggle = useRef(null)

    const menuToggle = () => {
        menuLeft.current.classList.toggle('active');
        btnToggle.current.classList.toggle('active');
    }

    const [activeIndex, setActiveIndex] = useState(null);
    const handleOnClick = index => {
        setActiveIndex(index);
    };

    const handleWalletClick = (e) => {
        if (nearWallet?.isSignedIn()) {
            setShowMenu(!showMenu);
        } else {
            login();
        }
    }

    const login = () => {
        nearWallet?.requestSignIn(
            NEAR.NFT_CONTRACT_NAME,
            'Hearhub Comics'
        );
    }

    const logout = () => {
        nearWallet?.signOut();
        setShowMenu(false);
    }    

    return (
        <header id="header_main" className="header_1 header_2 style2 js-header" ref={headerRef} style={{backgroundImage: 'url("/assets/diagmonds.png")', backgroundColor: '#030303'}}>
            <div className="themesflat-container">
                <div className="row">
                    <div className="col-md-12">
                        <div id="site-header-inner">
                            <div className="wrap-box flex">
                                <div id="site-logo" className="clearfix" style={{marginLeft: 'auto', marginRight: 'auto'}}>
                                    <div id="site-logo-inner">
                                        <Link to="/" rel="home" className="main-logo">
                                            <img id="logo_header" src={logo} alt="" />
                                        </Link>
                                    </div>
                                </div>
                                
                                <div className="flat-search-btn flex">
                                    <div className="sc-btn-top mg-r-12" id="site-header">
                                        <span onClick={handleWalletClick} className="sc-button header-slider style style-1 wallet fl-button pri-1 pointer" style={nearWallet?.isSignedIn()?{border:'none'}:{}}>
                                            <span>{ nearWallet?.isSignedIn() ? nearWallet.getAccountId() : 'Connect Wallet' }</span>
                                        </span>
                                    </div>

                                    <div className="" id="header_admin">
                                        <div className="header_avatar">
                                           <div className="avatar_popup mt-20" style={showMenu?{visibility:'visible',opacity:1}:{}}>
                                               {/*
                                                <div className="d-flex align-items-center copy-text justify-content-between">
                                                    <span> 13b9ebda035r178... </span>
                                                    <Link to="/" className="ml-2">
                                                        <i className="fal fa-copy"></i>
                                                    </Link>
                                                </div>
                                                <div className="d-flex align-items-center mt-10">
                                                    <img
                                                        className="coin"
                                                        src={coin}
                                                        alt="/"
                                                    />
                                                    <div className="info ml-10">
                                                        <p className="text-sm font-book text-gray-400">Balance</p>
                                                        <p className="w-full text-sm font-bold text-green-500">16.58 NEAR</p>
                                                    </div>
                                                </div>
                                                <div className="hr"></div>
                                                */
                                                }
                                                <div className="links">
                                                    {
                                                        /*
                                                    <Link to="#">
                                                        <i className="fab fa-accusoft"></i> <span> My items</span>
                                                    </Link>
                                                    <a className="mt-10" href="/edit-profile">
                                                        <i className="fas fa-pencil-alt"></i> <span> Edit Profile</span>
                                                    </a>
                                                    */ }
                                                    <a className="" onClick={() => {logout()}} id="logout">
                                                        <i className="fal fa-sign-out"></i> <span> Disconnect</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DarkMode />
        </header>
    );
}

export default HeaderStyle2WL;
